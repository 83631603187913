<template>
  <div class="exchange-detail">
    <div class="detail-cont">
      <div class="detail-item">
         <div class="layout-bottom">
          <div class="img">
            <img :src="order_detail.item_img" alt="">
          </div>
          <div class="img-txt">
            <p class="name">{{order_detail.item_name}}</p>
            <p class="number">&times;1</p>
          </div>
        </div>
        <div class="prize-time">
          <p class="title">中奖时间</p>
          <p v-if="type == 3" class="prize-account">{{order_detail.create_time}}</p>
          <p v-else class="prize-account">{{order_detail.create_time | formatDateTime}}</p>
          
        </div>
      </div>
      <div v-if="!Number(order_detail.is_exchange) && order_detail.recharge_type === 2" class="account">
        <div class="tip">
          <p class="tip-con">温馨提示：</p>
          <p class="tip-con">1.充值账号请仔细核对您中奖的奖品，再输入对应的账号；</p>
          <p class="tip-con">2.确认账号后，无法修改</p>
          <p class="tip-con">3.账号错误概不负责，请仔细核对账号</p>
          <p class="tip-con">4.客服联系电话：0898-32191718</p>
          <p class="tip-con">5.充值一般可在15分钟内到账，如遇充值高峰期24小时内到账。</p>
        </div>
        <div class="input">
          <p>充值帐号</p>
          <input class="input-layout" type="number" v-model="account" placeholder="请输入您对应的充值账号" />
        </div>
      </div>
    </div>

    <div v-if="order_detail.is_exchange === 0" class="confirm" @click="type === 3 ? receiveAwardTeam() : receiveAwardHaiyuansu()">
      确定领取
    </div>

    <div v-if="Number(order_detail.is_exchange) && order_detail.recharge_type == 2" class="account-recharge">
      <p>充值账号</p>
      <p>{{order_detail.recharge_account}}</p>
    </div>

    <div v-if="Number(order_detail.is_exchange) && order_detail.recharge_type == 1" class="success-tip">
      <div class="tip">
        <p class="tip-con">温馨提示：</p>
        <p class="tip-con">1.请仔细查看卡密内容，复制到对应平台的兑换处兑换即可</p>
        <p class="tip-con">2.卡密有有效时间，请尽快兑换，过期概不负责。</p>
      </div>

      <div class="exchange-kami">
        <div class="exchange-detail">
          <div class="exchange-txt">兑换卡密</div>
          <div v-if="order_detail.kami" class="copy-code-button" :data-clipboard-text="order_detail.kami" @click="copy">
            <!-- <img src="@/assets/haiyuansu/copy.png" alt=""> -->
          </div>
        </div>
        <p v-if="order_detail.kami" class="kami-con">{{order_detail.kami}}</p>
        <p v-else  class="kami-con">等待发放...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { payOrder, cancelOrder, getExchangeInfo, confirmReceipt } from "@/services/orderApi.js";
import { receiveAwardHaiyuansu, getDrawDetailHaiyuansu, receiveAwardTeam, getTeamUserDetail } from "@/services/my";
import Clipboard from 'clipboard'
import {debounce} from "@/utils/debounce";

export default {
  name: 'receive_award',
  data() {
    return {
      order_detail: [],
      account: '',
      item_id: this.$route.query.item_id || '',
      type: this.$route.query.type || ''
    }
  },
  created() {
    document.title = '领取奖励'
  },
  mounted() {
    console.log('type', this.type)
    if (this.type == 3) {
      this.getTeamUserDetail()
    } else {
      this.getDrawDetailHaiyuansu()
    }
    // this.getDrawDetailHaiyuansu()
    console.log('id', this.pay_order_id)
  },
  methods: {

    async getTeamUserDetail() {
      try {
        let data;
        let params = {
          team_user_id: this.item_id
        }
        data = await getTeamUserDetail(params);
        if (data.code === 0) {
          this.order_detail = data.data;
          this.order_status = data.data.order_status
          // getStore('ORDER_TYPE') ? '' : setStore('ORDER_TYPE', 1)
          // this.ORDER_TYPE = getStore("ORDER_TYPE");
          // this.pay_order_id = data.data.pay_order_id
          // this.order_detail.recharge_type === 1 ? this.source_type = '兑换卡密' : this.source_type = '充值账号'
        }
      } catch (err) {
        throw err;
      }
    },

    async getDrawDetailHaiyuansu() {
      try {
        let data;
        let params = {
          draw_log_id: this.item_id
        }
        data = await getDrawDetailHaiyuansu(params);
        if (data.code === 0) {
          this.order_detail = data.data;
          this.order_status = data.data.order_status
          // getStore('ORDER_TYPE') ? '' : setStore('ORDER_TYPE', 1)
          // this.ORDER_TYPE = getStore("ORDER_TYPE");
          // this.pay_order_id = data.data.pay_order_id
          // this.order_detail.recharge_type === 1 ? this.source_type = '兑换卡密' : this.source_type = '充值账号'
        }
      } catch (err) {
        throw err;
      }
    },

    //  组队抽奖领取接口
    receiveAwardTeam: debounce(async function () {
      console.log('组队')
      try {
        let params = {
          team_user_id: this.item_id,
          recharge_account: this.account
        }
        const res = await receiveAwardTeam(params)
        console.log(1)
        console.log(res.code)
        if (res.code === 0) {
          this.$dialog.toast({
            mes: "奖品领取成功，请注意查收",
            timeout: 1500
          });
          this.getTeamUserDetail()
        } else {
          this.$dialog.toast({
            mes: res.msg,
            icon: 'error',
            timeout: 1500
          });
        }
      } catch(e){
        this.$dialog.toast({
          mes: '网络繁忙，稍后再试',
          icon: 'error',
          timeout: 1500
        });
      }
    }, 2000),

    //  转存月月抽领取接口
    receiveAwardHaiyuansu: debounce(async function () {
      // 转存
      try {
        if (!this.account && this.order_detail.recharge_type === 2) {
          this.$dialog.toast({
            mes: '未填写充值账号',
            icon: 'error',
            timeout: 1500
          });
          return
        }
        let params = {
          draw_log_id: this.item_id || 0,
          recharge_account: this.account,
          recharge_type: this.order_detail.recharge_type
        }
        const res = await receiveAwardHaiyuansu(params)
        if (res.code === 0) {
          this.$dialog.toast({
            mes: "奖品领取成功，请注意查收",
            timeout: 1500
          });
          // location.reload() 
          this.getDrawDetailHaiyuansu()
        } else {
          this.$dialog.toast({
            mes: res.msg,
            icon: 'error',
            timeout: 1500
          });
        }
      } catch(e) {}
    }, 2000),

    copy() {
      let clipboard = new Clipboard('.copy-code-button') // 这里可以理解为选择器，选择上面的复制按钮
      clipboard.on('success', e => {
        this.$dialog.toast({
          mes: '复制成功'
        });
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$dialog.toast({
          mes: '手机权限不支持复制功能'
        });
        // 释放内存
        clipboard.destroy()
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/common/less/variable.less";
@import "~@/common/less/mixin.less";

.exchange-detail {

  .detail-cont {
    width: 100%;

    .detail-item {
      padding: 0.1rem 0.2rem 0 0.2rem;
      background: #fff;

      .layout-bottom {
        position: relative;
        overflow: hidden;

        .img {
          float: left;

          img {
            .wh(0.96rem, 0.96rem)
          }
        }

        .img-txt {
          height: 0.96rem;
          margin-left: 0.2rem;
          float: left;
          position: relative;

          .number {
            .sc(0.24rem, #999);
            position: absolute;
            bottom: 0.05rem;
          }

          .name {
            .sc(0.28rem, #333);
            width: 5rem;
            padding: 0.09rem 0;        
            overflow:hidden; //超出的文本隐藏
            text-overflow:ellipsis; //溢出用省略号显示
            white-space:nowrap; //溢出不换行
          }
        }
      }

      .prize-time {
        .sc(0.28rem, #666);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.2rem 0 0.2rem 0;
      }
    }

    .account {
      .sc(0.28rem, #666);
      padding: 0 0.2rem;
      margin-top: 0.2rem;
      background: #fff;
      width: 100%;
      flex-wrap: wrap;
      overflow: hidden;

      .tip {
        padding-top: 0.1rem;

        .tip-con {
          .sc(0.24rem, #999);
          padding: 0.1rem 0;
        }
      }

      .input {
        .sc(0.28rem, #666);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0.1rem 0 0.2rem 0;

        .input-layout {
          .wh(4rem, 0.6rem);
          background:#F2F2F2;
          border-radius: 0.64rem;
          padding: 0 0.2rem;
          line-height: 0.6rem;
        }

        ::-webkit-input-placeholder {
            color: #999;
            font-weight: 400;
            font-size: 0.24rem;
        }

        /* Firefox 4-18 */
        :-moz-placeholder {
            color: #999;
            font-weight: 400;
            font-size: 0.24rem;
        }

        /* Firefox 19-50 */
        ::-moz-placeholder {
            color: #999;
            font-weight: 400;
            font-size: 0.24rem;
        }

        /* - Internet Explorer 10–11
          - Internet Explorer Mobile 10-11 */
        :-ms-input-placeholder {
            color: #999 !important;
            font-weight: 400 !important;
            font-size: 0.24rem;
        }

        /* Edge (also supports ::-webkit-input-placeholder) */
        ::-ms-input-placeholder {
            color: #999;
            font-weight: 400;
            font-size: 0.24rem;
        }

        /* CSS Working Draft */
        ::placeholder {
            color: #999;
            font-weight: 400;
            font-size: 0.24rem;
        }
      }
    }
  }

  .confirm {
    margin: 0.28rem 0.2rem 0;
    height: 0.64rem;
    line-height: 0.64rem;
    background:  #FA5212;
    text-align: center;
    font-size: 0.28rem;
    border-radius: 0.64rem;
    color: #fff;
  }

  .account-recharge {
    .sc(0.28rem, #666);
    height: 0.8rem;
    background: #fff;
    padding: 0 0.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.2rem;
  }

  .success-tip {
    margin-top: 0.2rem;
    padding: 0 0.2rem;
    background: #fff;

    .tip {
      padding-top: 0.1rem;

      .tip-con {
        .sc(0.24rem, #999);
        padding: 0.1rem 0;
      }
    }

    .exchange-kami {
      .sc(0.28rem, #666);

      .exchange-detail, p {
        padding: 0.1rem 0;
        display: flex;
        align-items: center;
        overflow: hidden;
      }

      .kami-con {
        .sc(0.24rem, #999);
        padding-bottom: 0.2rem;
      }

      img {
        .wh(0.4rem, 0.4rem)
      }

      .copy-code-button {
        display: block;
        .wh(0.4rem, 0.4rem);
        background: url('~@/assets/haiyuansu/copy.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 0.2rem;
      }

      .exchange-txt {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>