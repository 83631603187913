var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "exchange-detail" }, [
    _c("div", { staticClass: "detail-cont" }, [
      _c("div", { staticClass: "detail-item" }, [
        _c("div", { staticClass: "layout-bottom" }, [
          _c("div", { staticClass: "img" }, [
            _c("img", { attrs: { src: _vm.order_detail.item_img, alt: "" } }),
          ]),
          _c("div", { staticClass: "img-txt" }, [
            _c("p", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.order_detail.item_name)),
            ]),
            _c("p", { staticClass: "number" }, [_vm._v("×1")]),
          ]),
        ]),
        _c("div", { staticClass: "prize-time" }, [
          _c("p", { staticClass: "title" }, [_vm._v("中奖时间")]),
          _vm.type == 3
            ? _c("p", { staticClass: "prize-account" }, [
                _vm._v(_vm._s(_vm.order_detail.create_time)),
              ])
            : _c("p", { staticClass: "prize-account" }, [
                _vm._v(
                  _vm._s(_vm._f("formatDateTime")(_vm.order_detail.create_time))
                ),
              ]),
        ]),
      ]),
      !Number(_vm.order_detail.is_exchange) &&
      _vm.order_detail.recharge_type === 2
        ? _c("div", { staticClass: "account" }, [
            _vm._m(0),
            _c("div", { staticClass: "input" }, [
              _c("p", [_vm._v("充值帐号")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.account,
                    expression: "account",
                  },
                ],
                staticClass: "input-layout",
                attrs: {
                  type: "number",
                  placeholder: "请输入您对应的充值账号",
                },
                domProps: { value: _vm.account },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.account = $event.target.value
                  },
                },
              }),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm.order_detail.is_exchange === 0
      ? _c(
          "div",
          {
            staticClass: "confirm",
            on: {
              click: function ($event) {
                _vm.type === 3
                  ? _vm.receiveAwardTeam()
                  : _vm.receiveAwardHaiyuansu()
              },
            },
          },
          [_vm._v("\n    确定领取\n  ")]
        )
      : _vm._e(),
    Number(_vm.order_detail.is_exchange) && _vm.order_detail.recharge_type == 2
      ? _c("div", { staticClass: "account-recharge" }, [
          _c("p", [_vm._v("充值账号")]),
          _c("p", [_vm._v(_vm._s(_vm.order_detail.recharge_account))]),
        ])
      : _vm._e(),
    Number(_vm.order_detail.is_exchange) && _vm.order_detail.recharge_type == 1
      ? _c("div", { staticClass: "success-tip" }, [
          _vm._m(1),
          _c("div", { staticClass: "exchange-kami" }, [
            _c("div", { staticClass: "exchange-detail" }, [
              _c("div", { staticClass: "exchange-txt" }, [_vm._v("兑换卡密")]),
              _vm.order_detail.kami
                ? _c("div", {
                    staticClass: "copy-code-button",
                    attrs: { "data-clipboard-text": _vm.order_detail.kami },
                    on: { click: _vm.copy },
                  })
                : _vm._e(),
            ]),
            _vm.order_detail.kami
              ? _c("p", { staticClass: "kami-con" }, [
                  _vm._v(_vm._s(_vm.order_detail.kami)),
                ])
              : _c("p", { staticClass: "kami-con" }, [_vm._v("等待发放...")]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("p", { staticClass: "tip-con" }, [_vm._v("温馨提示：")]),
      _c("p", { staticClass: "tip-con" }, [
        _vm._v("1.充值账号请仔细核对您中奖的奖品，再输入对应的账号；"),
      ]),
      _c("p", { staticClass: "tip-con" }, [_vm._v("2.确认账号后，无法修改")]),
      _c("p", { staticClass: "tip-con" }, [
        _vm._v("3.账号错误概不负责，请仔细核对账号"),
      ]),
      _c("p", { staticClass: "tip-con" }, [
        _vm._v("4.客服联系电话：0898-32191718"),
      ]),
      _c("p", { staticClass: "tip-con" }, [
        _vm._v("5.充值一般可在15分钟内到账，如遇充值高峰期24小时内到账。"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("p", { staticClass: "tip-con" }, [_vm._v("温馨提示：")]),
      _c("p", { staticClass: "tip-con" }, [
        _vm._v("1.请仔细查看卡密内容，复制到对应平台的兑换处兑换即可"),
      ]),
      _c("p", { staticClass: "tip-con" }, [
        _vm._v("2.卡密有有效时间，请尽快兑换，过期概不负责。"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }